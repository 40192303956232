import React from 'react';
import { Typography, CardContent, Card, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import Button from '../buttons';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import Backdrop from '../backdrop';
import { useIsMobile } from '../../util/style-utils';
import Alerts from '../alerts';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#fff',
    marginTop: theme.spacing(3),
  },
  textPrimary: {
    color: colors.noirBlur,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minWidth: '9em',
    padding: 0,
  },
  button: {
    margin: '1em',
    minWith: '9em',
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0 !important',
  },
  confirmation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  mfaConfirmation: {
    margin: '1em 0',
  },
}));

const RegistrationComplete = (props: {
  email?: string;
  groupName?: string;
}) => {
  const { customer: user } = useAccountCustomer();
  const classes = useStyles(props);
  const { richT, t } = useTranslation();
  const isMobile = useIsMobile();

  if (!props.email && !user) {
    return <Backdrop forceOpen />;
  }

  return (
    <Paper component="div" className={classes.paper}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Alerts
            disableColorOverride={true}
            severity={'success'}
            variant={'outlined'}
            title={
              Boolean(props.groupName)
                ? t('REGISTRATION_COMPLETE_WITH_GROUP_NAME', {
                    GROUP_NAME: props.groupName,
                  })
                : t('ONLINE_REGISTRATION_COMPLETE_TITLE')
            }
            message={
              <>
                <Typography component="div">
                  {richT('ONLINE_REGISTRATION_COMPLETE_BODY', {
                    email: props.email || user?.email,
                  })}
                </Typography>

                <Typography component="div" style={{ fontWeight: 'bold' }}>
                  {t('ENABLE_TWO_STEP_AUTHENTICATION')}
                </Typography>

                <Typography component="div" className={classes.mfaConfirmation}>
                  {t('WHAT_IS_TWO_STEP_AUTHENTICATION')}
                </Typography>

                <Grid className={classes.buttonContainer} container spacing={1}>
                  <Grid
                    style={{
                      display: 'flex',
                      justifyContent: isMobile ? 'center' : 'flex-end',
                    }}
                    item
                    xs={isMobile ? 10 : 12}
                  >
                    <Button
                      data-testid={'mfa-enrollment-button'}
                      onClick={() =>
                        navigate(ROUTES.TWO_STEP_AUTHENTICATION, {
                          state: { forceLogin: true },
                        })
                      }
                      className={classes.button}
                    >
                      {t('SETUP_TWO_STEP_AUTHENTICATION')}
                    </Button>
                    <Button
                      data-testid={'registration-complete-button'}
                      onClick={() => navigate(ROUTES.ACCOUNT)}
                      className={classes.button}
                    >
                      {t('GOTO_ONLINE_ACCOUNT_BUTTON')}
                    </Button>
                  </Grid>
                </Grid>
              </>
            }
            isOpen
          />
        </CardContent>
      </Card>
    </Paper>
  );
};

export default RegistrationComplete;
