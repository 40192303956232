import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';

import Alert from '../../components/alerts';
import AuthLayout from '../../components/auth-layout';
import RegistrationComplete from '../../components/registration-complete';
import { useTranslation } from '../../hooks/useTranslation';
import { RegistrationPageProps } from './index';
import { useLocation } from '@reach/router';
import { useIsMobile } from '../../util/style-utils';

type LocationState = {
  groupName?: string;
  email?: string;
};

const RegistrationCompletePage = (props: RegistrationPageProps) => {
  const { t } = useTranslation();
  const title = t('REGISTRATION_COMPLETE_HEADER');
  const isMobile = useIsMobile();
  const childProps = { ...props, isMobile };
  const [isAlertOpen, toggleAlertBox] = useState(true);

  const location = useLocation();
  const locationState = (location.state || {}) as LocationState;

  return (
    <>
      <Grid style={{ marginTop: '1em' }}>
        <Alert
          isOpen={isAlertOpen}
          severity={'success'}
          variant={'filled'}
          title={t('REGISTRATION_COMPLETE_SUBTITLE')}
          onClose={() => toggleAlertBox(!isAlertOpen)}
        />
      </Grid>
      <AuthLayout title={title} showLeft={false}>
        <RegistrationComplete
          {...childProps}
          email={locationState.email}
          groupName={locationState.groupName}
        />
      </AuthLayout>
    </>
  );
};

export default RegistrationCompletePage;
